<template>
  <div id="reports">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <h1>Reports</h1>
      <h4>{{ name }}</h4>
      <b-table responsive hover @row-clicked="rowClickHandler" :items="reports" :fields="fields">
        <template v-slot:cell(view)="row">
          <b-link
            :to="{ name: 'organisation-data', params: { organisationId: row.item.organisationId } }"
          >
            <font-awesome-icon :icon="['fas', 'table']" />
          </b-link>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";

export default {
  name: "reports",
  created() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData"
  },
  data() {
    return {
      title: "Reports",
      reports: [],
      fields: [{ key: "reportName", label: "Name" }],
      name: null,
      organisationId: null
    };
  },
  methods: {
    async fetchData() {
      var organisationId = this.$route.params.organisationId;
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}reports?organisationId=${organisationId}`
        );

        if (response.data.status === "success") {
          this.reports = response.data.reports;
          this.name = response.data.organisation.name;
          this.organisationId = response.data.organisation.organisationId;
        } else {
          this.showWarning("There was a problem getting organisation reports.");
        }
      } catch {
        this.showWarning("There was a problem getting organisation reports.");
      }
    },
    rowClickHandler(data) {
      const a = this.$router.resolve({
        name: "report",
        params: {
          organisationId: this.organisationId,
          reportname: data.reportName
        }
      });
      window.open(a.href, "_blank");
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    }
  }
};
</script>